<template>
  <div class="mine">
    <div class="head">
      <span class="name">{{ name }}</span>
    </div>
    <div class="head2">
      <span class="adr">{{ adr }}</span>
    </div>
    <!-- <van-cell title="修改密码" is-link to="/updatePass" /> -->
    <!-- <van-cell title="开关阀门记录" is-link to="/mine/actionList" /> -->
    <div @click="linkToPass">
      <van-cell title="修改密码" is-link />
    </div>
    <div style="margin: 25px" @click="logout">
      <van-button round type="info" block>退出登录</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: localStorage.getItem("phone"),
      adr: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.initData();
    }, 1000);
  },
  created() {},
  computed: {},
  methods: {
    initData() {
      let code = this.$route.query.qrresult;
      if (code) {
        this.$dialog
          .confirm({ title: "提示", message: "确认添加该设备？" + code })
          .then(() => {
            this.http
              .post("/gas/device/add", { gasDeviceType: 2, deviceId: code,other:true })
              .then((res) => {
                if (res.data.code == 200) {
                  this.$toast.success('添加成功');
                }
              });
          })
          .catch(() => {});
      }
    },
    addDevice() {
      if (!this.is_weixn()) {
        alert("请用微信打开页面，扫码功能仅支持微信页面");
        return;
      }
      sessionStorage.setItem("isInSerach", "1");
      let hrefStr = location.href;
      let href = hrefStr;
      if (hrefStr.indexOf("?") !== -1) {
        href = hrefStr.split("?")[0];
      }
      location.href = `https://www.996315.com/api/scan?redirect_uri=${encodeURIComponent(
        href
      )}`;
    },
    is_weixn() {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    },
    logout() {
      localStorage.setItem("sessionId", "");
      localStorage.setItem("companyId", "");
      this.$router.replace("/login");
      location.reload
    },
    linkToPass(){
      this.$router.push('/updatePass')
    },
  },
};
</script>
<style lang="less" scoped>
.mine {
  height: 100%;
  width: 100%;
  .head {
    background-color: rgb(42, 138, 218);
    padding-top: 100px;
    color: #fff;
    font-size: 1.6em;
    text-align: center;
  }
  .head2 {
    background-color: rgb(42, 138, 218);
    padding-top: 20px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 1.6em;
    text-align: center;
  }
}
</style>
